import langArr from './langs';
$(() => {
  const langs = document.querySelectorAll('.language');
  const allLang = ['eng', 'ru', 'uz'];

  const changURLLang = (id) => {
    location.href = `${window.location.pathname}#${id}`;

    changeLang(id);
  };

  langs.forEach((l) =>
    l.addEventListener('click', (e) => {
      e.preventDefault();
      // if (l.id == 'ru') {
      //   window.location.hash = 'ru';
      //   location.reload();
      // }
      changURLLang(l.id);
    }),
  );

  const changeSectionLang = (item, key, i) => {
    if (typeof key == 'object' && !Array.isArray(key)) {
      for (let k in key) {
        item.querySelectorAll(`.lng-${k}`).forEach((itm, j) => {
          changeSectionLang(itm, key[k], j);
        });
      }
      return;
    }

    if (Array.isArray(key)) {
      if (key[i]) {
        item.innerHTML = key[i];
      }
      return;
    }

    if (key) {
      item.innerHTML = key;
    }
    return;
  };

  const changeLang = () => {
    let hash = window.location.hash;
    hash = hash.substring(1);

    if (!allLang.includes(hash)) {
      window.location.hash = 'eng';
      hash = 'eng';
    }

    langs.forEach((lang) => {
      if (lang.id == hash) {
        lang.classList.add('active');
      } else {
        lang.classList.remove('active');
      }
    });

    document.getElementsByName('lang').forEach((item) => {
      item.value = hash;
    });

    for (let key in langArr[hash]) {
      document.querySelectorAll(`.lng-${key}`).forEach((item, i) => {
        changeSectionLang(item, langArr[hash][key], i);
      });
    }
  };

  changeLang();
});
