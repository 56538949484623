const langArr = {
  eng: {
    contact: [
      'Limited Liability Company',
      '"CENTRAL MARKET SOLUTION"',
      'Republic of Uzbekistan,Tashkent',
      'Reg №: 305710838',
      'Registration date 20.08.2018',
    ],
    antifrod: {
      title: 'Anti-fraud',
      text: [
        'Our solutions allow you to track any suspicious activity on voice and SMS traffic initiated on the equipment of our partners. Fraud traffic is blocked instantly with an accuracy of 98%. Our anti-fraud system is a unique development using complex algorithms for processing big data and self-learning artificial intelligence. Using our anti-fraud, you can be sure that your voice and SMS traffic is insured against any fraudulent activity.',
      ],
      btn: 'To learn more',

      labels: ['Company name', 'name', 'contact number', 'e-mail'],
      formBtn: 'send',
    },

    sms: {
      title: 'Data processing',
      text: [
        'Provision of a software and hardware complex for processing Voice and SMS traffic to provide SMS distribution, and provision of voice service (telephony) processing by means of SIP for corporate clients. The system routes traffic in the right directions using the specified parameters, selects the highest quality routes for the delivery of packages, SMS data and voice services.',
        'Provision of data in accordance with a specific procedure or order by selection online or through direct access to active data classified according to the order (computerized management) for a wide or limited range of users.',
      ],
      btn: 'To learn more',
      labels: ['Company name', 'name', 'contact number', 'e-mail'],
      formBtn: 'send',
    },
  },
  uz: {
    contact: [
      "Mas'uliyati cheklangan jamiyat",
      '"CENTRAL MARKET SOLUTION"',
      "O'zbekiston Respublikasi, Toshkent",
      'STIR: 305710838',
      "Ro'yxatga olingan sana: 20.08.2018",
    ],
    antifrod: {
      title: 'Antifrod',
      text: [
        "Bizning yechimlarimiz hamkorlarimiz uskunalaridagi ovozli va SMS trafikda yuzaga kelgan har qanday shubhali harakatni kuzatish imkonini beradi. Frod trafiki 98% gacha aniqlik bilan bir zumda bloklanadi. Bizning antifrod tizimimiz murakkab katta ma'lumotlarni qayta ishlash algoritmlari va mustaqil o'rganadigan sun'iy intellektdan foydalanadigan noyob ishlanmadir. Bizning antifroddan foydalanib, sizning ovozli va SMS trafikingiz har qanday firibgarlik harakatlaridan sug‘urtalanganligiga ishonch hosil qilishingiz mumkin.",
      ],
      btn: "Qo'shimcha ma'lumot olish uchun",
      labels: ['Kompaniya nomi', 'ismi', 'aloqa raqami', 'e-mail'],
      formBtn: 'yuborish',
    },
    sms: {
      title: "ma'lumotlarni qayta ishlash",
      text: [
        "SMS tarqatilishini ta'minlash uchun Ovozli va SMS trafigini qayta ishlash bo'yicha dasturiy-apparat kompleksini taqdim etish va korporativ mijozlar uchun SIP orqali ovozli servisni (telefoniya) qayta ishlashni ta'minlash. Tizim belgilangan parametrlar bo'yicha trafikni kerakli yo'nalishlarga yo'naltiradi, paketlar, SMS ma'lumotlar va ovozli xizmatlarni etkazib berish uchun eng sifatli marshrutlarni tanlaydi.",
        "Ma'lumotni ma'lum tartib yoki buyurtma bo'yicha onlayn tanlash, keng yoki cheklangan foydalanuvchilar doirasi uchun tartib bo'yicha tasniflangan (kompyuter tomonidan boshqariladigan) faol ma'lumotlarga to'g'ridan-to'g'ri kirish orqali taqdim etish.",
      ],
      btn: "Qo'shimcha ma'lumot olish uchun",
      labels: ['Kompaniya nomi', 'ismi', 'aloqa raqami', 'e-mail'],
      formBtn: 'yuborish',
    },
  },
  ru: {
    contact: [
      'Общество с Ограниченной Ответственностью',
      '"CENTRAL MARKET SOLUTION"',
      'Республика Узбекистан, г. Ташкент',
      'ПНФЛ: 305710838',
      'Дата регистрации 20.08.2018',
    ],

    antifrod: {
      title: 'антифрод',
      text: [
        'Наши решения позволяют отслеживать любую подозрительную активность на голосовом и СМС трафике, инициированном на оборудовании наших партнеров. Фрод-трафик блокируется моментально с точностью до 98%. Наша антифрод-система является уникальной разработкой с использованием сложных алгоритмов обработки big data и самообучаемого искусственного интеллекта. Используя наш антифрод, Вы можете быть уверены, что ваш голосовой и СМС трафик застрахованы от любой мошеннической активности.',
      ],
      btn: 'Узнать больше',
      labels: ['Название компании', 'Имя', 'Контактный телефон', 'E-mail'],
      formBtn: 'отправить',
    },
    sms: {
      title: 'Обработка Данных',
      text: [
        'Предоставление программно-аппаратного комплекса для обработки Голосового и Смс трафика для предоставления рассылки СМС, и предоставление обработки голосового сервиса (телефонии) по средствам SIP для корпоративных клиентов. Система маршрутизирует трафик по нужным направлениям с применением заданных параметров, выбирает наиболее качественные маршруты для доставки пакетов, данных СМС и голосовых сервисов. ',
        'Предоставление данных в соответствии с определенной процедурой или заказом путем выбора в режиме онлайн или через прямой доступ к активным данным, классифицированным в соответствии с заказом (компьютеризированное управление) для широкого или ограниченного круга пользователей. ',
      ],
      btn: 'Узнать больше',
      labels: ['Название компании', 'Имя', 'Контактный телефон', 'E-mail'],
      formBtn: 'отправить',
    },
  },
};

export default langArr;
